@import "../abstracts/vars";

/**
  PT Sans
*/
@font-face {
  font-family: 'PT Sans';
  src: url(#{$assetsPath}fonts/PTSans/PTSans-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans';
  src: url(#{$assetsPath}fonts/PTSans/ptsans.woff2) format('woff2'),
  url(#{$assetsPath}fonts/PTSans/ptsans.woff) format('woff'),
  url(#{$assetsPath}fonts/PTSans/ptsans.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

/**
 Montserrat
*/
@font-face {
  font-family: 'Montserrat';
  src: url(#{$assetsPath}fonts/Montserrat/Montserrat-SemiBold.eot);
  src: url(#{$assetsPath}fonts/Montserrat/Montserrat-SemiBold.eot?#iefix) format('embedded-opentype'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-SemiBold.woff2) format('woff2'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-SemiBold.woff) format('woff'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(#{$assetsPath}fonts/Montserrat/Montserrat-Light.eot);
  src: url(#{$assetsPath}fonts/Montserrat/Montserrat-Light.eot?#iefix) format('embedded-opentype'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-Light.woff2) format('woff2'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-Light.woff) format('woff'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(#{$assetsPath}fonts/Montserrat/Montserrat-Medium.eot);
  src: url(#{$assetsPath}fonts/Montserrat/Montserrat-Medium.eot?#iefix) format('embedded-opentype'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-Medium.woff2) format('woff2'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-Medium.woff) format('woff'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(#{$assetsPath}fonts/Montserrat/Montserrat-ExtraBold.eot);
  src: url(#{$assetsPath}fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix) format('embedded-opentype'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-ExtraBold.woff2) format('woff2'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-ExtraBold.woff) format('woff'),
  url(#{$assetsPath}fonts/Montserrat/Montserrat-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}