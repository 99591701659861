.modal {
  .modal-dialog {

    .modal-content {
      border: none;
      border-radius: 12px;

      .modal-body {
        @media screen and (max-width: 767px) {
          padding: .3rem;
        }
      }
    }

    &.modal-full-width {
      max-width: 90%;
    }
  }

  .modal__info {
    color: black;
    font-size: 16px;
    margin: 16px 0;
    line-height: 22px;

    @media (max-width: 480px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__icon {
    &--close {
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      width: 0.9375rem;
      cursor: pointer;
    }
  }

  &__list {
    padding-left: 1.25rem;
  }

  &__button {
    padding: 0.875rem 2.5rem 1rem;
    width: unset;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 0 !important;

    &>button{
      width: 264px;
    }
  }
}