.channels {
    padding-right: 15px;
    height: 100%;

    //@include media-breakpoint-down(md) {
        //height: calc(75vh - 10px);
    //}

    .channel {
        padding: 15px;

        &__avatar {
            left: 15px;
        }
    }
}

.channel {
    display: flex;
    padding: 15px 0;
    position: relative;
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    text-decoration: none;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &:hover {
        text-decoration: none;

        & > .channel__subscription {
            visibility: visible;
            opacity: 1;
        }
    }

    &__avatar {
        position: absolute;
        left: 0;
        top: 50%;
        width: 2.8rem;
        height: 2.8rem;
        transform: translateY(-50%);
        border-radius: 50%;
        overflow: hidden;

        img {
            max-width: 100%;
        }
    }

    &__content {
        padding-left: 3.5rem;
        color: initial;
        text-decoration: none;
        width: 100%;

        div {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &:first-child {
                margin-bottom: .3rem;
            }
        }
    }

    &__date {
        width: 55%;
        text-align: right;

        //@include media-breakpoint-down(sm) {
        //    font-size: 9px;
        //}
    }

    &__text {
        width: 80%;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__count {
        background-color: #C4C4C4;
        border-radius: 100px;
        color: #ffffff;
        padding: 1px 5px;
    }

    &__subscription {
        display: flex;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        right: 15px;
        bottom: -15px;
        min-width: 160px;
        align-items: center;
        padding: 10px 15px;
        background-color: #fff;
        color: #000000;
        box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
        z-index: 1;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: inherit;
        }

        img {
            width: 1.25rem;
            height: 1.25rem;
            object-fit: contain;
            margin-right: 0.625rem;
        }
    }

    &__subscription-spinner {
        width: 10px;
        height: 10px;
    }
}