@import "../../../blocks/colors";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.notifications {
    width: 40vw;
    height: auto;
    padding: 1.875rem;
    background-color: #F9F9F9;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    @media screen and (max-width: 480px) {
        width: 60vw;
    }

    &__show-all {
        margin: 0;

        .button {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }

    &--full-screen {
        width: 100%;
        height: 100%;

        .notifications__heading_wrapper {
            margin-bottom: 3rem;
        }

        .notifications__heading {
            font-size: 2.25rem;

            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
        }

        @include media-breakpoint-down(md) {
            width: calc(100% + 30px);
        }
    }

    @include media-breakpoint-down(md) {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: -15px;
        margin-right: -15px;
        box-shadow: none;
        border-radius: 12px;
    }
}

.notification {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 4.7rem;
    padding: 10px;
    background-color: #fff;
    border-radius: 12px;
    text-decoration: none;

    &.unread {
        background-color: #EAF1FF;
    }

    &:hover {
        text-decoration: none;
    }

    &__avatar {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 3.125rem;
        height: 3.125rem;
        min-width: 3.125rem;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__icon {
        display: flex;
        position: absolute;
        right: -5px;
        bottom: -5px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;

        i {
            display: block;
            width: 14px;
            height: 14px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__info {
        padding-left: 4rem;

        &_desc {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $black;
        }
    }
}

.notification-setting {
    position: relative;

    &__icon {
        display: flex;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
        min-width: 22px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        i {
            display: block;
            width: 14px;
            height: 14px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__info {
        padding-left: 1.875rem;
        padding-right: 3.75rem;

        @include media-breakpoint-down(md) {
            padding-left: 2.5rem;
        }
    }

    &__selector {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &__heading {
        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }
}
.notifications-position {
    width: 95vw !important;
    left: 0;
    top: 0 !important;
    transform: translateY(-105%);
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom:10px ;
    margin-left: 0;
    margin-right: 0;

    @media(max-width: 650px) {
        width: 90vw !important;
}
    @media(max-width: 480px) {
        width: 85vw !important;
    }
}