@import "../colors";

.dropdown-menu {
  padding: 0;
  margin: 0;
  border-radius: 2px;
  background: #FFFFFF;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.05);
  border: 0;

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem;

    &:hover {
      background-color: #F3F5F7;
    }

    &:focus, &:active, &:focus:active {
      outline: none;
      border: 0;
      background-color: #F3F5F7;
      color: $black;
    }
  }

  &.dropdown-share {
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    .dropdown-item {
      padding: 0;

      &:not(:last-child) {
        margin-right: 1.3rem;
      }

      &:hover, &:focus, &:focus:active {
        background-color: #fff;
      }
    }

    i {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      cursor: pointer;
    }
  }
}