.tippy-box[data-theme~='invalid'] {
    background-color: #FFE9E7;
    color: #1F0303;
    border: 1px solid #E28F8C;
    width: 100%;
    max-width: 100% !important;
}

.tippy-box[data-theme~='invalid'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #FFE9E7;
}

.tippy-box[data-theme~='invalid'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: #FFE9E7;
}

.tippy-box[data-theme~='invalid'][data-placement^='left'] > .tippy-arrow::before {
    border-left-color: #FFE9E7;
}

.tippy-box[data-theme~='invalid'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: #FFE9E7;
}