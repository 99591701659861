@import "../colors";

.hidden {
  display: none;
}

.js-validate-container {
  margin-bottom: 24px;
  position: relative;

  i {
    position: absolute;
    top: 12px;
    right: 16px;
    display: inline-block;
    width: 24px;
    height: 24px;

    @media (max-width: 767px) {
      top: 7px;
    }
  }
}

.form {
  width: 100%;
  background-color: #fff;
  padding: 2.5rem;
  z-index: 1;

  &--inline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 767px) {
      padding: 1rem;
    }

    .form__group {
      display: inline-block;
      position: relative;
      margin: .5rem 1.5rem .5rem 0;

      @media screen and (max-width: 767px) {
        display: block;
        margin: .5rem auto;
        width: 100%;

        .form__input {
          width: 100%;
          max-width: unset;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--dropdown {
    padding: 2rem;
    min-width: 27rem;

    @media screen and (max-width: 767px) {
      min-width: unset;
    }

    &__checkboxes {
      overflow: auto;
      max-height: 14rem;
    }

    &__btns {
      display: flex;
      justify-content: flex-end;

      .button {
        display: inline-block;
        width: auto;
      }
    }
  }

  &__input:focus-within, &__input:not(:placeholder-shown) {
    padding: 1.4rem 1rem .4rem 1rem;
    outline: none;

    ~ .form__input_label {
      position: absolute;
      top: .1rem;
      font-size: 0.75rem;
      opacity: 1;
    }
  }

  &__input {
    width: 100%;
    height: 3rem;
    padding: 1.4rem 1rem .4rem 1rem;
    border: 1px solid $lightGrey;
    border-radius: 8px;
    font-size: 1rem;
    color: $black;

    &:focus-within, &:focus, &:not(:placeholder-shown), &:-webkit-autofill {
      padding: 1.4rem 1rem .4rem 1rem;
      outline: none;

      & ~ .form__input_label {
        position: absolute;
        top: .1rem;
        font-size: 0.75rem;
        opacity: 1;
      }
    }

    &:focus, &:not(:placeholder-shown) {
      padding: 1.4rem 1rem .4rem 1rem;
      outline: none;

      ~ .form__input_label {
        position: absolute;
        top: .1rem;
        font-size: 0.75rem;
        opacity: 1;
      }

      @media (max-width: 480px) {
        padding: 10px 16px;
      }
    }

    &:not(&__date) {
      &::placeholder {
        opacity: 1;
      }
    }

    &:disabled {
      background-color: $lightGrey;
      opacity: .5;
    }

    &_label {
      position: absolute;
      width: calc(100% - 2rem);
      pointer-events: none;
      left: 1rem;
      top: .8rem;
      font-size: 1rem;
      line-height: 1.33;
      color: #6a6e72;
      transition: .3s;
      opacity: .5;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;

      &--show {
        position: absolute;
        top: .3rem;
        font-size: 0.75rem;
        opacity: 1;
      }

      @media (max-width: 480px) {
        top: 0;
        left: 16px;
        transform: translateY(-100%);
        font-size: 0.75rem;
        line-height: 16px;
        color: black;
        opacity: 1;
      }
    }

    &_error {
      border-color: $primary;
      color: $primary;
    }

    &_help {
      background-color: #FFF4EC;
      margin-top: 8px;
      padding: 6px 16px;
      color: #B95000;
      border-radius: 8px;
      font-size: 12px;

      &--medium {
        font-size: 14px;
        padding: 12px 16px;
        margin-bottom: 24px;

        @media (max-width: 480px) {
          font-size: 12px;
          margin-bottom: 20px;
        }
      }

      i {
        position: static;
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: top;
        background-repeat: no-repeat;

        @media (max-width: 480px) {
          display: none;
        }
      }

      @media (max-width: 480px) {
        padding: 12px 16px;
      }
    }

    &.js-analytics-country,
    &.js-analytics-region {
      height: auto;
    }
  }

  &__group {
    position: relative;

    &_dadata {

      &--transparent {
        input {
          padding: 1rem .5rem !important;
          font-family: "PT Sans", sans-serif;
          border: 0;
          color: #fff;
          background-color: transparent;
        }
      }

      .suggestions-suggestions {
        border: 0;
        border-radius: 2px;
        box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
        padding: .3rem;
      }

      .suggestions-suggestions strong {
        color: #540575;
      }

      .suggestions-addon[data-addon-type=clear] {
        display: none !important;
      }
    }

    &__select {
      &.full-width-select {
        &--mw-400 {
          div.dropdown-menu {
            max-width: 400px;

            a {
              span.text {
                max-width: 400px;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form__input_label {
  z-index: 10;
}

.form__input_text {
  &--low {
    height: 2rem;
  }
}

.form__input_text_label_before {
  padding: .4rem 1rem .4rem 1rem !important;
}

.custom-checkbox-switch {

  & > input[type="checkbox"] {
    position: absolute;
    right: 0;
    cursor: pointer;
    width: 40px;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    outline: none;
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
    transition: 0.5s;

    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: 0;
      left: 0;
      background: #fff;
      transform: scale(1.1);
      box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
      transition: 0.5s;
    }

    &:checked {
      background: rgb(84, 5, 117);

      &::before {
        left: 20px;
      }
    }

    &:disabled {
      background: rgb(200, 200, 200) !important;
    }
  }
}
