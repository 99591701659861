@import "../../../abstracts/vars";
@import "~bootstrap/scss/mixins";
@import "../../../blocks/form/form";
@import "../../../blocks/form/__input_textarea/_input_textarea";
@import "channels";
@import "./new-channel";

$paddingVertical: 1.875rem;
$paddingHorizontal: 1.25rem;
$buttonSize: 5.625rem;
$chatModalWidth: 45vw;
$chatModalHeight: 70vh;

.chat-widget {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1000;
    max-height: 100vh;

    @include media-breakpoint-down(md) {
        z-index: 9999;
        width: auto;
        height: auto;

        &.opened {
            width: 100%;
        }
    }
}

.chat {
    &__screen {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        padding: 0 20px 20px 0;
        overflow: hidden;
    }

    &__modal {
        display: none;
        width: 100%;
        height: 100%;
        padding: $paddingHorizontal $paddingVertical;
        margin-bottom: 20px;
        background-color: #F9F9F9;
        box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        transition: all .3s;

        >div {
            display: none;
            overflow: hidden;

            &.active {
                display: block;
                height: 100%;
            }
        }
    }

    &__button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $buttonSize;
        height: $buttonSize;
        min-width: $buttonSize;
        min-height: $buttonSize;
        margin: 0;
        padding: 0;
        user-select: none;
        border: none;
        border-radius: 50%;
        background-color: #540575;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
        background-repeat: no-repeat;
        background-position: center;
        transition: all .3s;

        &.unread {
            &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                width: 1.5rem;
                height: 1.5rem;
                background-color: #3D3AE4;
                border-radius: 50%;
            }
        }

        &.opened {
            background-image: url(#{$assetsPath}img/icons/chat/close.svg);

            @include media-breakpoint-down(md) {
                background-size: 40%;
            }
        }

        &.closed {
            background-image: url(#{$assetsPath}img/icons/chat/message.svg);

            @include media-breakpoint-down(md) {
                background-size: 70%;
            }
        }

        &:focus {
            outline: none;
        }

        &:active {
            outline: none;
            transform: translateY(2px);
            opacity: .9;
        }
    }
}

.chat-modal {
    position: relative;
    width: $chatModalWidth;
    height: $chatModalHeight;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        width: 100%;
        max-height: 80vh;
        left: 5px !important;
    }

    &__header {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        background-color: #F9F9F9;
        padding: 0 $paddingVertical;

        &--channels {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
        }

        &--back {
            align-items: center;
            width: 100%;

            a {
                display: flex;
                align-items: center;
                color: inherit;
                text-decoration: none;

                &:hover {
                    color: inherit;
                    text-decoration: none;
                }
            }

            p {
                margin-left: 5px;
            }

            &.active {
                display: flex;
            }
        }
    }

    &__content {
        padding-top: 50px;
        height: 100%;

        &--wrapper {
            height: 100%;
        }
    }

    .chat-widget-iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    .native-channel {
        height: 100%;
        overflow-y: auto;

        &__wrapper {

            .post {
                background: #ffffff;
                padding: 15px;
                margin-top: 1rem;

                &__channel-name {
                    font-weight: bold;
                }

                &__created-at {
                    color: #6a6e72;
                    text-align: right;

                    @include media-breakpoint-down(md) {
                        text-align: left;
                    }
                }

                &__content {
                    margin-top: 1rem;

                    p {
                        margin-bottom: 1rem;
                    }
                }
            }

            .post:first-child {
                margin-top: 0;
                border-top: none;
            }
        }
    }
}

.arrow {
    display: inline-block;
    width: 22px;
    height: 22px;
    background-size: contain;
    background-repeat: no-repeat;

    &--left {
        margin-left: -5px;
        background-image: url(#{$assetsPath}img/icons/chat/arrow-left-blue.svg);
    }
}