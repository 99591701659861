@import "../../abstracts/vars";

.icon {
  display: inline-block;
  width: 1.375rem;
  height: 1.375rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > a {
      display: flex;
      align-items: center;
    }
  }

  &--small {
    width: 0.9375rem;
    height: 0.9375rem;
  }

  &--large {
    width: 2.5rem;
    height: 2.5rem;
  }

  &--bookmark {
    background-image: url(#{$assetsPath}img/icons/bookmark.svg);
  }

  &--bookmark-filled {
    background-image: url(#{$assetsPath}img/icons/bookmark-filled.svg);
  }

  &--share {
    background-image: url(#{$assetsPath}img/icons/share.svg);
  }

  &--alert {
    background-image: url(#{$assetsPath}img/icons/alert.svg);
  }

  &--check {
    background-image: url(#{$assetsPath}img/icons/check.svg);
  }

  &--check-green {
    background-image: url(#{$assetsPath}img/icons/check-green.svg);
  }

  &--alarm {
    background-image: url(#{$assetsPath}img/icons/alarm.svg);
  }

  &--email {
    background-image: url(#{$assetsPath}img/icons/social/email-color-pink.svg);
  }

  &--email-color {
    background-image: url(#{$assetsPath}img/icons/social/email-color.svg);
  }

  &--vk {
    background-image: url(#{$assetsPath}img/icons/social/vk-color-pink.svg);
  }

  &--vk-color {
    background-image: url(#{$assetsPath}img/icons/social/vk-color.svg);
  }

  &--vk-circle {
    background-image: url(#{$assetsPath}img/icons/social/vk-circle.svg);
  }

  &--skype {
    background-image: url(#{$assetsPath}img/icons/social/skype-color-pink.svg);
  }

  &--skype-color {
    background-image: url(#{$assetsPath}img/icons/social/skype-color.svg);
  }

  &--whatsapp {
    background-image: url(#{$assetsPath}img/icons/social/whatsapp-color-pink.svg);
  }

  &--whatsapp-color {
    background-image: url(#{$assetsPath}img/icons/social/whatsapp-color.svg);
  }

  &--facebook {
    background-image: url(#{$assetsPath}img/icons/social/fb-color-pink.svg);
  }

  &--facebook-color {
    background-image: url(#{$assetsPath}img/icons/social/fb-color.svg);
  }

  &--fb-circle {
    background-image: url(#{$assetsPath}img/icons/social/fb-circle.svg);
  }

  &--instagram {
    background-image: url(#{$assetsPath}img/icons/social/instagram-color-pink.svg);
  }

  &--instagram-color {
    background-image: url(#{$assetsPath}img/icons/social/instagram-color.svg);
  }

  &--telegram {
    background-image: url(#{$assetsPath}img/icons/social/telegram-color-pink.svg);
  }

  &--telegram-color {
    background-image: url(#{$assetsPath}img/icons/social/telegram-color.svg);
  }

  &--youtube {
    background-image: url(#{$assetsPath}img/icons/social/youtube-color-pink.svg);
  }

  &--youtube-color {
    background-image: url(#{$assetsPath}img/icons/social/youtube-color.svg);
  }

  &--odnoklassniki {
    background-image: url(#{$assetsPath}img/icons/social/odnoklassniki-color-pink.svg);
  }

  &--odnoklassniki-color {
    background-image: url(#{$assetsPath}img/icons/social/odnoklassniki-color.svg);
  }

  &--ok-circle {
    background-image: url(#{$assetsPath}img/icons/social/ok-circle.svg);
  }

  &--tiktok {
    background-image: url(#{$assetsPath}img/icons/social/tiktok-color-pink.svg);
  }

  &--tiktok-color {
    background-image: url(#{$assetsPath}img/icons/social/tiktok-color.svg);
  }

  &--clip {
    background-image: url(#{$assetsPath}img/icons/clip.svg);
  }

  &--web-blue {
    background-image: url(#{$assetsPath}img/icons/social/global-grey.svg);
  }

  &--vk-white {
    background-image: url(#{$assetsPath}img/icons/social/vk-white.svg);
  }

  &--vk-pink {
    background-image: url(#{$assetsPath}img/icons/social/vk-color-pink.svg);
    width: 20px;
    height: 20px;
    background-size: 30px;
  }

  &--odnoklassniki-pink {
    background-image: url(#{$assetsPath}img/icons/social/odnoklassniki-color-pink.svg);
    width: 20px;
    height: 20px;
    background-size: 15px;
  }

  &--tiktok-white {
    background-image: url(#{$assetsPath}img/icons/social/tiktok-white.svg);
  }

  &--instagram-white {
    background-image: url(#{$assetsPath}img/icons/social/instagram-white.svg);
  }

  &--instagram-pink {
    background-image: url(#{$assetsPath}img/icons/social/instagram-color-pink.svg);
    width: 20px;
    height: 20px;
    background-size: 20px;
  }

  &--subscription-remove {
    background-image: url(#{$assetsPath}img/icons/subscription-remove.svg);
  }

  &--subscription-add {
    background-image: url(#{$assetsPath}img/icons/subscription-add.svg);
  }

  &--thumb-up-not-filled {
    background-image: url(#{$assetsPath}img/icons/thumb-up-not-filled.svg);
  }

  &--thumb-up-filled {
    background-image: url(#{$assetsPath}img/icons/thumb-up-filled.svg);
  }

  &--file-grey {
    background-image: url(#{$assetsPath}img/icons/file-grey.svg);
  }

  &--photo-grey {
    background-image: url(#{$assetsPath}img/icons/photo-grey.svg);
  }

  &--video-grey {
    background-image: url(#{$assetsPath}img/icons/video-grey.svg);
  }
}

#add-icon .cropper-view-box {
  border-radius: 50%;
  outline: none;
  border: 1px solid #39f;
}

.record-icon--pos {
  margin-right: .5rem;
}

.footer {

  &__email {
    color: #000;
  }
}