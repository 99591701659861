$WEBPACK_ENV: dev;
@import "fonts";
@import "colors";
@import "~bootstrap/scss/bootstrap";
@import "button/button";
@import "list/list";
@import "../abstracts/vars";
@import "icon/icon";
@import "~toastr/toastr";
@import "dropdown/dropdown";
@import "breadcrumbs/breadcrumbs";
@import "../blocks/modal/modal";
@import "../pages/_snippets/chat/chat";
@import "../pages/_snippets/notifications/notifications";
@import "./tippy/invalid";

// Стили редактора
@import '~summernote/dist/summernote-bs4.css';

html,
body {
  height: 100%;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6, p, a {
  margin: 0;
  padding: 0;
}

ul {
  margin-bottom: 0;
}

h1, .h1 {
  font-family: Montserrat, sans-serif;
  font-size: 2.25rem;
  line-height: 2.625rem;
  font-weight: 800;
}

h2 {
  font-family: Montserrat, sans-serif;
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 800;
}

h3 {
  font-family: Montserrat, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 800;
}

h4 {
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 800;

  &.font-size-16 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

h5 {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 800;
}

h6 {
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  line-height: 0.875rem;
  font-weight: 800;
}

img {
  max-width: 100%;
}

.button--primary {
  color: $white !important;
  background-color: $primary;
}

.button--primary {
  color: $white !important;
  background-color: $primary;
}

p {
  font-family: PT Sans, sans-serif;
  font-size: 1rem;

  &.font-size-20 {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }

  &.font-size-18 {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  &.font-size-16 {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  &.font-size-14 {
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
  }

  &.caps {
    font-size: 0.687rem;
    line-height: 1rem;
    letter-spacing: 1px;
  }
}

a {
  font-family: PT Sans, sans-serif;
  line-height: 1.4rem;
  font-size: 1rem;
  color: #540575;
  text-decoration: none;
  transition: .3s;
  word-break: break-word;

  &:hover {
    color: #540575;
  }

  &.disable-hover-line {
    color: inherit;
    text-decoration: none;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    color: $lightGrey;
  }
}

img, iframe {
  max-width: 100%;
}

.billet {
  background-image: url(#{$assetsPath}img/project/view/billet-bg.png);
  background-repeat: no-repeat;
  font-weight: normal;
  line-height: 22px;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  padding: 4px 15px;
  width: fit-content;

  &:hover {
    color: #fff !important;
    text-decoration: none !important;
  }
}

section {
  padding: 3rem 0;

  &.vertical-align-middle {
    display: flex;
    align-items: center;
  }

  &:first-child {
    padding-top: 10rem;
  }
}

.font-size-20 {
  font-size: 1.25rem;
  line-height: 1.625rem;
}

.font-size-18 {
  font-size: 1.125rem;
  line-height: 1.625rem;
}

.font-size-16 {
  font-size: 1rem;
  line-height: 1.375rem;
}

.font-size-14 {
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
}

.font-size-12 {
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.16px;
}

.caps {
  font-size: 0.687rem;
  line-height: 1rem;
  letter-spacing: 1px;
}

.bold-600 {
  font-weight: 600;
}

.bold-800 {
  font-weight: 800;
}

.color {
  &--pink {
    color: $primary;
  }

  &--light-pink {
    color: $lightPink
  }

  &--grey {
    color: $grey;
  }

  &--light-grey {
    color: $lightGrey;
  }

  &--white,
  &--white:hover {
    color: #fff;
  }

  &--blue {
    color: $primary;
  }

  &--purple {
    color: $primary;

    &:hover {
      color: $primary;
    }
  }
}

label {
  margin: 0;
}

header {
  z-index: 1000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 1.5rem 0;

  &-button--active {
    background-color: #000AFF14;
    border-radius: 8px;
  }

  &__items {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 0.7rem;

        a {
          font-family: Montserrat, sans-serif;

          &:hover {
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }
  }

  &__icons {
    align-items: center;

    &_link {
      display: flex;
      align-self: center;
      transition: none !important;

      &--action {
        padding: 12px;
      }

      &:hover {
        text-decoration: none;
      }

      i {
        display: inline-block;
        width: 1.375rem;
        height: 1.375rem;
        background-position: center;
        background-repeat: no-repeat;
      }

      .search-icon {
        background-image: url(#{$assetsPath}img/icons/search-white.svg);
      }

      .mail {
        background-image: url(#{$assetsPath}img/icons/email-header-white.svg);
      }

      .notify {
        background-image: url(#{$assetsPath}img/icons/notify-white.svg);

        &.active {
          background-image: url(#{$assetsPath}img/icons/notify-white-active.svg);
        }
      }

      .dots {
        background-image: url(#{$assetsPath}img/common/dots.svg);
      }
    }
  }

  &__avatar {
    display: block;
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    margin-right: 4px;
    //border: 1px solid #fff;
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__logo {
    width: 143px;

    @media screen and (max-width: 1023px) {
      width: 50px;
      margin-right: 16px;
    }

    @media screen and (max-width: 480px) {
      width: 28px;
    }

    @media screen and (max-width: 425px) {
      img {
        width: 80px;
      }
    }
  }

  &__logo_pink {
    display: none;
    max-width: 140px;
    min-width: 140px;
    width: 140px;
  }

  &__logo_adaptive {
    display: none !important;

    @media screen and (max-width: 1023px) {
      height: 50px;
      width: 50px;
      display: block !important;

      & > img {
        height: 50px;
        width: 50px;
      }
    }

    @media screen and (max-width: 480px) {
      height: 28px;
      width: 28px;
      margin-right: 16px;

      & > img {
        height: 28px;
        width: 28px;
      }
    }
  }

  &__logo_white {
    max-width: 140px;
    min-width: 140px;
    width: 140px;
    display: block !important;

    @media screen and (max-width: 1023px) {
      display: none !important;
    }
  }

  &.black {

    .header__logo_pink {
      max-width: 140px;
      min-width: 140px;
      width: 140px;
      display: block !important;

      @media screen and (max-width: 1023px) {
        display: none !important;
      }
    }

    .js-toggle-list::after {
      content: url(#{$assetsPath}img/common/selector-arrow-black.svg) !important;
    }

    .header__logo_white {
      max-width: 140px;
      min-width: 140px;
      width: 140px;
      display: none !important;
    }

    ul {
      li {
        a {
          color: $black;
        }
      }
    }

    .button.button--login {
      border-color: $primary;
      color: $primary;

      &:hover,
      &:active {
        background-color: $primary;
        color: #fff;
      }
    }

    .header__icons {
      &_link {
        .search-icon {
          background-image: url(#{$assetsPath}img/icons/search.svg);
        }

        .mail {
          background-image: url(#{$assetsPath}img/icons/email-header.svg);
        }

        .notify {
          background-image: url(#{$assetsPath}img/icons/notify-black.svg);

          &.active {
            background-image: url(#{$assetsPath}img/icons/notify-black-active.svg);
          }
        }

        .dots {
          background-image: url(#{$assetsPath}img/common/dots-black.svg);
        }
      }
    }

    .hamburger-menu .menu__btn > span,
    .hamburger-menu .menu__btn > span::before,
    .hamburger-menu .menu__btn > span::after {
      background-color: $black;
    }
  }
}

.footer {
  padding: 40px 0;
  background-color: #EDF0F3;

  .apps {
    & > div {
      display: flex;

      &:last-child {
        margin-top: 10px;
      }

      @media(max-width: 1200px) {
        &:last-child {
          margin-top: 7px;
        }
      }
    }

    a {
      display: block;
      max-width: 136px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      @media(max-width: 1200px) {
        max-width: 130px;

        &:not(:last-child) {
          margin-right: 7px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      display: flex;

      & > div {
        &:last-child {
          margin-top: 0;
        }
      }

      a:last-child {
        margin-right: 10px;
      }
    }

    @media screen and (max-width: 430px) {
      div {
        display: flex;
        flex-direction: column;
        margin-top: 0 !important;

        a {
          margin-top: 10px;
        }
      }
    }
  }


  ul {

    li {

      &:not(:last-child) {
        @media screen and (min-width: 576px) {
          margin-right: 50px;
        }
      }

      a {
        font-family: Montserrat, sans-serif;
        color: #000;

        &:hover {
          text-decoration: none;
          color: #540575;
        }
      }

      @media screen and (max-width: 767px) {
        display: block;
        text-align: center;
      }
    }
  }

  .footer-email {
    display: inline-block;
    width: 1.125rem;
    height: 1.125rem;
    margin-right: .8rem;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .download {
    a {
      img {
        display: inline-block;
        width: auto;
        height: 40px;

        &:first-child {
          margin-right: .8rem;
        }
      }

      &:hover {
        text-decoration: none;
        color: transparent;
      }
    }
  }

  .submit-mail {
    input {
      height: 48px;
      background-color: transparent;
      border: 1px solid #E6E9EE;
      border-radius: 8px;
      color: #fff;

      &:focus {
        padding: 1.1rem 1rem .4rem .7rem;
        outline: none;

        + .submit-mail__input_label {
          top: .3rem;
          left: .75rem;
          opacity: .5;
          font-size: 0.75rem;
        }
      }

      &:not(:focus) {
        &:not(:placeholder-shown) {
          padding: 1.1rem 1rem .4rem .7rem;

          & + .submit-mail__input_label {
            top: .3rem;
            left: .75rem;
            opacity: .5;
            font-size: 0.75rem;
          }
        }
      }
    }

    .submit-mail__input_label {
      position: absolute;
      pointer-events: none;
      left: 15px;
      top: 14px;
      font-size: 1rem;
      line-height: 1.33;
      color: #000000;
      opacity: 0.5;
      transition: .3s;
    }

    .button {
      padding: 0;
      position: relative;

      &:before {
        content: '';
        width: 38px;
        height: 38px;
        background-image: url(#{$assetsPath}img/new-version/icons/footer-send.svg);
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        position: absolute;
        top: 4px;
        right: 4px;
        background-color: rgba(109, 117, 128, 0.1);
        border-radius: 5px;
      }
    }
  }

  .awards {
    border-top: 1px solid #E6E9EE;

    &__achievement {
      margin-right: 1.5rem;

      @media screen and (max-width: 500px) {
        width: 10%;
      }

      &:nth-child(3) {
        margin-right: 1.5rem;
      }

      &:hover {
        text-decoration: none;
      }

      svg {
        width: auto;
        height: 40px;

        @media screen and (max-width: 500px) {
          height: 30px;
        }
      }
    }

    &__link {
      margin-top: .5rem;
      margin-bottom: .5rem;

      svg {
        width: auto;
        height: 40px;
        transition: .3s;
      }

      &:hover {
        color: transparent;
        text-decoration: none;

        svg {
          filter: unset;
        }
      }
    }
  }

  &__bottom {
    background-color: #E6E9EE;

    a {
      color: #878787;
    }

    p {
      color: #878787;
    }
  }

  &-bottom {
    &__left {
      &-item:not(:last-child) {
        margin-right: 1.25rem;
      }

      @media screen and (max-width: 575px) {
        margin-bottom: 10px;
      }
    }

    &__right {
      margin-left: auto;
      max-width: 500px;
    }

    &__operator {
      color: #878787;
      font-size: 0.75rem;
      line-height: 0.875rem;

      &-link {
        flex-shrink: 0;
        margin-left: 8px;

        img {
          width: 46px;
          height: 24px;
        }

        &--rosmolodeg {
          img {
            width: 94px;
          }
        }
      }
    }
  }
}

input, input:before, input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

.cover-img {
  position: relative;
}

.tippy-content {
  text-align: left;
  font-weight: 400;
}

.header-services {
  background-color: #fff;
  text-decoration: none;
  padding: 0;
  margin: 0;
  min-width: 260px;
  width: 100%;
  z-index: 3;
  overflow: hidden;

  &-all {
    display: none;

    &--show {
      display: block;
    }
  }

  &:first-child {
    margin-right: 24px;
  }

  li {
    width: 100%;
    margin: 0 !important;
  }

  @include media-breakpoint-down(md) {
    min-width: 300px;
  }

  &__link {
    position: relative;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;

    &:hover {
      text-decoration: none;
      background-color: rgba(84, 5, 117, 0.05);

      @media screen and (max-width: 1024px) {
        background-color: white;
      }

      .header-services__desc {
        color: #000;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    color: #000000;

    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      object-fit: contain;
    }

    span {
      font-family: 'Montserrat', sans-serif;
      font-weight: 800;
      font-size: 1rem;
    }
  }

  &__desc {
    font-size: 1rem;
    color: #878787;
  }

  &__icon-left {
    transform: rotate(180deg);
  }

}

.user-menu {
  min-width: 312px;
  font-size: 14px;
  line-height: 18px;
  transform: translate3d(-224px, 50px, 0px) !important;
  border-radius: 12px;
  box-shadow: 0 40px 98px 0 rgba(0, 0, 0, 0.24);

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 3;
    overflow-x: hidden;
    overflow: hidden;
    border-radius: 12px;
  }

  &__item {
    display: flex;
    width: 100%;
    margin: 0 !important;
    transition: background-color .3s;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__type-dashboard {

    @media screen and (max-width: 1024px) {
      display: flex;
    }

    .user-menu__label {
      color: #0C1014;

      &:hover {
        text-decoration: none;
        color: #0C1014;
      }
    }
  }

  & .user-menu__actions {
    margin-top: 8px;
    border-radius: 0;
  }
}

.adaptive-menu .user-menu__actions {
  margin-top: 16px;
}

.user-menu__icon {
  left: 25px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;

  &__type-common {

    .user-menu__link {
      padding: 10px 25px;
      align-items: center;
      display: flex;


      @media screen and (max-width: 1024px) {
        padding: 10px 0;
      }
    }

    .user-menu__label {
      padding-left: 32px;
      color: #0C1014;
      display: flex;
      align-items: center;


      @media screen and (max-width: 1024px) {
        padding-left: 0;
      }

      &:hover {
        text-decoration: none;
        color: #0C1014;
      }
    }

    .user-menu__icon {
      position: absolute;
      left: 25px;
      top: 10px;
      width: 22px;
      height: 22px;
      object-fit: contain;

      &--indent {
        margin-left: 3px;
      }

      @media screen and (max-width: 1024px) {
        position: relative;
        left: 0;
        top: 0;
        margin-right: 12px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  html, body {
    font-size: 14px;
  }
  .header {
    justify-content: space-between;

    &__items {
      display: none;

      ul {
        li {
          margin-right: .3rem;
        }
      }
    }

    .hamburger-menu {
      display: block;
    }
  }
}

@media screen and (max-width: 767px) {
  html, body {
    font-size: 12px;
  }

  header {
    .container {
      max-width: 100%;
    }
  }
}

@media print {
  header, footer, .footer__bottom, .chat-widget, .streaming-button, .streaming-widget, .modal {
    display: none;
  }

  .section:first-child {
    padding-top: 0 !important;
  }
}

.volunteer-edit__tabs {
  padding: 0 !important;
  margin: 0 !important;

  &-item {
    display: none !important;

    &--active {
      display: block !important;
    }
  }
}


.volunteer-edit__tabs-link {
  color: #0C1014 !important;

  &--active {
    color: #540575 !important;
  }
}

.header {

  .dropdown-menu-center {
    left: 50% !important;
    right: auto !important;
    text-align: center !important;
    transform: translate(-50%, 0) !important;
    top: 60px !important;

    &--adaptive {
      @media screen and (max-width: 1400px) {
        left: 40% !important;
        transform: translate(-60%, 0) !important;
      }

      @media screen and (max-width: 1260px) {
        left: 35% !important;
        transform: translate(-65%, 0) !important;
      }
    }
  }

  .dropdown-menu__select {
    width: 250px;
    top: 10px !important;
    left: 120px !important;
  }

  .header-menu-select {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);

    &__item {
      padding: 16px 25px;
      width: 100%;

      &:not(:last-child) {
        border-bottom: 1px solid #D6DADE;
      }

      color: #0c1014;

      &:hover {
        color: #0c1014 !important;
        background-color: #F3F5F7;
      }

      &:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }

  .js-toggle-list::after {
    border: 0 !important;
    content: url(#{$assetsPath}img/common/selector-arrow-white.svg);
    margin-left: 0.25rem;
    transform: rotate(0deg);
  }

  .header__award {
    width: 80%;

    @media screen and (max-width: 1200px) {
      width: 42%;
    }

    div {
      text-align: center;

      a:first-child {
        color: #FF954D;
        background-color: #F9F9F9;
        border-radius: 4px;
        padding: 4px 8px;
      }

    }

    a {
      font-family: 'Montserrat', sans-serif;
      color: #000;
      font-size: 0.9rem;
      padding: 6px 10px;
      display: inline-block;
      text-decoration: none;
    }
  }

  .header__search {
    width: 100%;

    @media screen and (max-width: 767px) {
      width: 72%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media screen and (min-width: 576px) {
      margin-left: 16px;
    }
  }
}

.header {
  &-search {
    position: relative;
    cursor: pointer;

    &-icon {
      display: none;

      @media screen and (max-width: 480px) {
        display: block;
        height: 13px;
        width: 13px;
        position: absolute;
        top: 9px;
        left: 9px;
      }
    }

    &__placeholder {
      position: absolute;
      display: inline-block;
      top: 16px;
      left: 12px;
      color: #878787;
      text-decoration: none;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (max-width: 480px) {
        top: 8px;
        left: 28px;
      }
    }

    &__input {
      width: 100%;
      min-width: 300px;
      height: 48px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 8px;

      @media screen and (max-width: 480px) {
        height: 32px;
        min-width: auto;
      }
    }

    &__button {
      position: absolute;
      top: 5px;
      right: 5px;
      height: 38px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: #540575;

      @media screen and (max-width: 480px) {
        display: none;
      }
    }
  }

  &-notification {
    &__icon {
      cursor: pointer;
      min-width: 20px;
    }
  }

  &-menu {
    &__dropdown {
      &-icon {
        min-width: 24px;
      }

      &-text {
        color: #000;
        white-space: nowrap;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__user {
    color: #000;
    text-decoration: none;

    &:hover {
      color: #000;
      text-decoration: none;
    }

    &-name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__button {
    &--login {
      color: #000;

      &:hover {
        color: #000;
        text-decoration: none;
      }
    }

    &--register {
      padding: 14px 10px;
      border: 1px solid #540575;
      border-radius: 8px;
      color: #000;

      &:hover {
        color: #fff;
        background-color: #540575;
        text-decoration: none;
        transition: all 0.3ms;
      }
    }
  }
}

.menu {

  &__list {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;

    &--main {
      padding: 16px;
      width: 100%;
    }
  }

  &-services {
    display: flex;
    align-items: flex-start;
  }

  &__list {
    display: flex;
  }

  &-actions {

    &__title {
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 10px;
      padding-left: 12px;
      text-align: start;
    }

    &__list {
      display: flex;
      flex-direction: column;
    }

    &__item {
      display: flex !important;
      white-space: nowrap;
      align-items: center;
      height: 48px;
      padding: 12px;
      font-weight: bold;
      margin: 0 !important;

      &:hover {
        background-color: $primary;
        border-radius: 12px;
      }

      a {
        color: #000;
      }
    }
  }
}


.menu__items--mobile {
  a {
    &:first-child {
      color: #FFA800;
      font-weight: 600;
    }

    &:last-child {
      color: #000;
      font-weight: 600;
    }
  }
}

.menu__input--mobile {
  max-width: 400px;
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding-left: 40px;
  background-image: url(#{$assetsPath}img/new-version/icons/search-grey.svg);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center left 8px;

  @media screen and (max-width: 464px) {
    width: 90%;
  }

  &:disabled {
    background-color: #fff;
  }

  &::placeholder {
    color: #878787;
  }
}

.video-preview {
  overflow: hidden;
  height: 150px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    height: 100px;
  }

  img {
    max-width: unset !important;
    width: 100%;
    height: auto;
  }
}

.project-videos {
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.dashboard-certificate {
  max-height: 216px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 400px) {
    height: 150px;
  }

  &__block {
    border-radius: 10px;
    overflow: hidden;
    max-height: 216px;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 400px) {
      height: 150px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.contacts-icons {
  position: absolute;
  left: 15px;
  top: 80px;

  @media(max-width: 1200px) {
    top: 76px;
  }

  @media(max-width: 767px) {
    position: static;
  }

  &__items {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    @media(max-width: 475px) {
      flex-direction: column
    }
  }

  &__item {
    max-width: 177px;
    display: flex !important;
    align-items: center;
    text-align: start;

    &:first-child {
      max-width: 146px;
    }

    @media(max-width: 1200px) {
      &:not(:last-child) {
        margin-right: 4px !important;
      }
    }

    @media(max-width: 991px) {
      &:not(:last-child) {
        margin-right: 54px !important;
      }
    }

    @media(max-width: 767px) {
      &:not(:last-child) {
        margin-right: 15px !important;
      }
    }

    @media (max-width: 475px) {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__image {
    margin-right: 6px;
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: #878787;
    margin-bottom: 0;
  }
}

.nav {
  height: 60px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  justify-content: space-between;
  padding: 10px 20px;
  display: none;

  @media screen and (max-width: 1200px) {
    display: flex;
  }

  &-backdrop {
    background-color: white;
    z-index: 2051;
    width: 100%;
    height: 60px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .adaptive-menu {
    position: relative;
    width: 44px;
    height: 34px;

    &--active {

      .nav__item-text, & > a > span {
        color: #540575;
      }

      & > label > svg > path, & > a > svg > path {
        fill: #540575 !important;
      }
    }

    #menu__toggle, #services__toggle, #user__toggle {
      position: relative;
      z-index: 500;
      opacity: 0;
      width: 45px;
      height: 35px;

      &:checked ~ .menu__box {
        visibility: visible;
        right: 0;
        @media screen and (max-width: 1200px) {
          right: -6px;
        }
      }
    }

    .menu__btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 45px;
      height: 35px;
      cursor: pointer;
      z-index: 1;
    }

    .menu__box {
      display: block;
      position: fixed;
      visibility: hidden;
      top: 0;
      overflow: hidden;
      right: -100%;
      width: 100%;
      height: 100%;
      margin: 0;
      padding-top: 100px;
      padding-bottom: 80px;
      list-style: none;
      background-color: white;
      transition-duration: .25s;

      @media screen and (max-width: 480px) {
        padding-top: 80px;
      }

      & > ul {
        list-style: none;
      }

      &-cross {
        display: block;
        position: absolute;
        right: 24px;
        top: 20px;
        height: 24px;
        width: 24px;
        background-size: cover;
      }

      &-text {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        text-align: left;
        color: black;
        margin-left: 32px;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        &--special {
          color: #FFA800;
        }
      }

      &_item {
        display: block;
        padding: .6rem 1.5rem;
        color: #000;
        opacity: 0.6;
        text-decoration: none;
        transition-duration: .25s;

        &:hover {
          text-decoration: none;
        }

        &.disabled {
          color: #ffffff8a;
          pointer-events: none;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 20px;
        left: 15px;
        width: 32px;
        height: 32px;
        background-image: url(#{$assetsPath}img/common/dobro-smile.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: 480px) {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;

    &--active {

      & span {
        color: white;
        font-size: 6px;
        padding: 1px 3px;
        position: absolute;
        top: 3px;
        right: 22px;
        display: inline-block;
        border-radius: 50%;
        background-color: #540575;
        border: 1px solid #fff;
      }
    }

    &-text {
      margin-top: 2px;
      font-weight: 400;
      font-size: 10px;
      color: black;
      height: 12px;

      &--active {
        color: #540575;
      }
    }
  }
}

.header__avatar--mobile {
  width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
}

.label {
  border-radius: 16px;
  background-color: rgba(232, 238, 245, 1);
  padding: 16px;
  margin-bottom: 8px;
}

.user-menu-container {
  width: 100%;
  max-height: 600px;
  top: 80px;
  left: 1084px;
  padding: 12px 12px 2px;
  border-radius: 20px 0 0 0;

  @media screen and (max-width: 1200px) {
    padding-top: 0;
    padding-right: 18px;
  }

  ul {
    padding-left: 0;
  }
}

.user-menu-wrapper {
  background-color: rgb(246, 248, 251);
  border-right: 16px;
  border-radius: 16px;
}

.user-menu-wrapper:not(:last-child) {
  margin-bottom: 8px !important;
}

.user-menu__block {
  padding-bottom: 4px;
}

.user-menu__last-icon {
  min-height: 30px;
  max-height: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  width: 30px;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.user-menu__link {
  display: flex;
  align-items: center;
  padding: 4px 12px;

  &:hover {
    text-decoration: none;
    color: #0C1014;
  }

  &--volunteer {
    margin-bottom: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: rgb(246, 248, 251);
    border-radius: 16px;
  }
}

.user-menu__link-icon {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 6px;
}

.user-menu__action-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  & img {
    object-fit: contain;
  }
}

.user-menu__label {
  font-size: 14px;
  line-height: 18px;
}

.user-menu__label-pink {
  color: #540575 !important;
}

.user-menu__block-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 4px;
  padding: 6px 16px;
  background-color: #e8eef5;
  border-radius: 16px;
  line-height: 18px;
  font-weight: 500;
}

.modal {
  &_container {
    display: none;
    padding-right: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    outline: 0;
  }

  &-dialog {
    max-width: 800px;
    position: relative;
    width: auto;
    margin: 1.75rem auto;
    pointer-events: none;
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
    border: unset;
    font-family: "EuclidCircularB", sans-serif;
    border-radius: 20px;
  }

  &-body {
    position: relative;
    flex: 1 1 auto;
    padding: 30px;
  }

  &__title {
    text-align: center;
    font-size: 32px;
    font-weight: 600;

    @media (max-width: 991px) {
      font-size: 20px;
      margin-bottom: 12px;
    }
  }

  &__description {
    margin: 20px auto 0 auto;
    max-width: 500px;
    text-align: center;

    @media (max-width: 991px) {
      font-size: 14px;
    }
  }

  &__list {
    margin: 20px auto 0 auto;
    max-width: 520px;
    padding-left: 30px;

    @media (max-width: 991px) {
      font-size: 14px;
      padding-left: 10px;
    }

    li {
      list-style-type: none;
      margin-top: 10px;

      @media (max-width: 991px) {
        margin-top: 5px;
      }

      &:before {
        display: inline-block;
        content: "";
        width: 8px;
        max-width: 8px;
        height: 8px;
        max-height: 8px;
        background-color: #878787;
        border-radius: 50%;
        margin-right: 10px;
        margin-bottom: 2px;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #540575;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: 991px) {
      font-size: 14px;
    }

    &:hover {
      color: #540575;
      text-decoration: none;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-top: 30px;
    width: 100%;

    @media (max-width: 991px) {
      flex-direction: column;
      gap: 10px 0;
    }
  }

  &__button {
    height: 48px;
    width: 240px;
    background-color: #540575;
    border: 1px solid transparent;
    color: #fff !important;
    padding: 14px 15px;
    outline: none !important;
    border-radius: 8px;
    text-decoration: none !important;
    text-align: center;

    @media (max-width: 991px) {
      width: 100%;
      height: 40px;
    }

    a {
      height: 48px;
      width: 240px;

      @media (max-width: 991px) {
        height: 40px;
        width: 100%;
      }
    }
  }
}

.user-menu-container .with-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #e4e5e6 transparent;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #e4e5e6;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  @media (min-width: 1200.1px) {
    max-height: 384px;

    @media (max-height: 650px) {
      max-height: 284px;
    }

    @media (max-height: 550px) {
      max-height: 184px;
    }

    @media (max-height: 430px) {
      max-height: 134px;
    }
  }
}

div.temporary-widget-class__container .temporary-widget-class__button {
  display: none;
}

.widget-button {
  z-index: 999996;
  position: fixed;
  bottom: 33px;
  right: 17px;
  width: 50px;
  height: 50px;
  background-color: #540575;
  border-radius: 50%;
  border: none;
  outline: none !important;
  background-image: url(#{$assetsPath}img/icons/widget-services.svg);
  background-size: 64%;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-height: 700px) {
    bottom: 5px;
  }

  @media screen and (max-width: 1200px) {
    bottom: 73px;
  }
}

.widget-button.opened {
  background-image: url(#{$assetsPath}img/icons/widget-cross-white.svg);
}

div.temporary-widget-class__container .temporary-widget-class__iframe-wrapper {
  @media screen and (max-width: 1200px) {
    bottom: 133px;
    max-height: calc(100vh - 230px);

    @supports (height: 100dvh) {
      max-height: calc(100dvh - 140px);
    }
  }
}

.verification-warning{
  font-family: 'Euclid Circular B', sans-serif;
  background-color: rgba(254, 238, 151, 0.28);
  color: black;
  border-radius: 12px;
  font-size: 12px;
  padding: 16px;
  margin: 15px 0 24px 15px;

  & > a {
    font-size: 12px;
    color: $primary;
  }
}
