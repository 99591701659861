@import "../colors";
@import "../../abstracts/vars";

.list {
  padding: 0;

  &__link {
    line-height: 1.375rem;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 1rem;
    list-style: none;
    margin: .5rem 0;
    line-height: 1.375rem;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0.55em;
      width: .5em;
      height: .5em;
      border-radius: 50%;
      background-color: $primary;
    }

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
    }

    &--no-dot {
      padding-left: 0;

      &:before {
        content: unset;
      }
    }

    &--date-icon {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/date.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.375rem;
        height: 1.375rem;
        left: 0;
        top: 0;
      }

      &--black {
        &:before {
          background: url(#{$assetsPath}img/search/calendar-black.svg) no-repeat center;
          background-size: contain;
        }
      }

      &--white {
        &:before {
          background: url(#{$assetsPath}img/icons/calendar-white.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    &--location-icon {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/location-grey.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.375rem;
        height: 1.375rem;
        left: 0;
        top: 0;
      }

      &--black {
        &:before {
          background: url(#{$assetsPath}img/event/view/location.svg) no-repeat center;
          background-size: contain;
        }
      }

      &--white {
        &:before {
          background: url(#{$assetsPath}img/icons/location-white.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    &--subscription-list-icon {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/event/view/subscription-defview.svg) no-repeat center;
        background-size: cover;
        border-radius: unset;
        width: 1.0625em;
        height: 0.9375rem;
        left: 0;
        top: 4px;
      }

      &--black {
        &:before {
          top: 5px;
          background: url(#{$assetsPath}img/icons/subscription-black.svg) no-repeat center;
          background-size: cover;
        }
      }

      &--white {
        &:before {
          background: url(#{$assetsPath}img/icons/subscription-white.svg) no-repeat center;
          background-size: cover;
        }
      }
    }

    &--people-icon {
      padding-left: 2rem;

      &:before {
        background-size: contain;
        border-radius: unset;
        width: 1.375rem;
        height: 1.375rem;
        left: 0;
        top: 0.1875rem;
      }

      &--black {
        &:before {
          background: url(#{$assetsPath}img/event/view/people.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    &--red-file-icon {
      padding-left: 2rem;
      padding-right: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/file.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.375rem;
        height: 1.375rem;
        left: 0;
        top: 0;
      }
    }

    &--grey-file-icon {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/file-grey.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.375rem;
        height: 1.375rem;
        left: 0;
        top: 0;
      }
    }

    &--check-icon {
      padding-left: 2rem;

      &:before {
        background-size: contain;
        border-radius: unset;
        width: 1.375rem;
        height: 1.375rem;
        left: 0;
        top: 0;
      }

      &--black {
        &:before {
          background: url(#{$assetsPath}img/icons/check-black.svg) no-repeat center;
          background-size: contain;
        }
      }

      &--no-bg-green {
        &:before {
          background: url(#{$assetsPath}img/icons/check-no-bg-green.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    &--planet-icon {
      padding-left: 2rem;

      &:before {
        background-size: contain;
        border-radius: unset;
        width: 1.375rem;
        height: 1.375rem;
        left: 0;
        top: 0;
      }

      &--black {
        &:before {
          background: url(#{$assetsPath}img/icons/planet.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    &--phone-icon {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/phone.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.3em;
        height: 1.3em;
        left: 0;
        top: .2em;
      }
    }

    &--email-icon {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/email.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.3em;
        height: 1.3em;
        left: 0;
        top: .2em;
      }
    }

    &--condition {
      padding-left: 0;

      .list__item_icon {
        display: block;
        width: 1.375rem;
        height: 1.375rem;
        margin-right: .7rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      &:before, &:after {
        content: unset;
      }

    }

    &--man {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/man.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.3em;
        height: 1.3em;
        left: 3px;
        top: 0;
      }
    }

    &--phone-grey {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/phone.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.3em;
        height: 1.3em;
        left: 0;
        top: .2em;
      }
    }

    &--email-grey {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/email-grey.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.125em;
        height: 1.125em;
        left: 0;
        top: .2em;
      }
    }

    &--www-grey,
    &--www-icon {
      padding-left:2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/global-grey.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.125em;
        height: 1.125em;
        left: 0;
        top: .2em;
      }
    }

    &--edu {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/edu.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.375rem;
        height: 1.375rem;
        left: 0;
        top: 0;
      }
    }

    &--job {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/job.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.375rem;
        height: 1.375rem;
        left: 0;
        top: 0;
      }
    }

    .list__item_close {
      position: absolute;
      width: 1.3rem;
      height: 1.3rem;
      margin-left: 1rem;
      right: 0;

      &:after {
        content: url(#{$assetsPath}img/icons/cross.svg);
        background-size: cover;
        position: absolute;
        width: 100%;
        right: 0;
        top: 55%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    &--calendar {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/dashboard/event.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.3em;
        height: 1.3em;
        left: 0;
        top: 1px;
      }
    }

    &--alarm {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/alarm.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.3em;
        height: 1.3em;
        left: 0;
        top: 2px;
      }
    }

    &--success {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/crm/approved.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.2em;
        height: 1.2em;
        left: 0;
        top: 2px;
      }
    }

    &--awards {
      padding-left: 2rem;

      &:before {
        background: url(#{$assetsPath}img/icons/crm/awards.svg) no-repeat center;
        background-size: contain;
        border-radius: unset;
        width: 1.3em;
        height: 1.3em;
        left: 0;
        top: 1px;
      }
    }

    &--cross {
      padding-left: 1.5rem;

      &:before {
        background-size: contain;
        border-radius: unset;
        width: 1.3em;
        height: 1.3em;
        left: 0;
        top: .1em;
      }

      &--grey {
        &:before {
          background: url(#{$assetsPath}img/icons/cross-grey.svg) no-repeat center;
          background-size: contain;
        }
      }

      &--pink {
        &:before {
          width: 1em;
          height: 1em;
          top: .3em;
          background: url(#{$assetsPath}img/icons/cross-pink.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    &--success-no-bg {
      padding-left: 1.5rem;

      &:before {
        background-size: contain;
        border-radius: unset;
        width: 1.3em;
        height: 1.3em;
        left: 0;
        top: .1em;
      }

      &--green {
        &:before {
          background: url(#{$assetsPath}img/icons/check-no-bg-green.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    &--paper {
      padding-left: 2rem;

      &:before {
        background-size: contain;
        border-radius: unset;
        width: 1.2em;
        height: 1.2em;
        left: .15rem;
        top: .05rem;
      }

      &--grey {
        &:before {
          background: url(#{$assetsPath}img/icons/paper.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    &--star {
      padding-left: 2rem;

      &:before {
        background-size: contain;
        border-radius: unset;
        width: 1.2em;
        height: 1.2em;
        left: .1em;
        top: 0;
      }

      &--black {
        &:before {
          background: url(#{$assetsPath}img/icons/star-black.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    &--clock {
      padding-left: 2rem;

      &:before {
        background-size: contain;
        border-radius: unset;
        width: 1.2em;
        height: 1.2em;
        left: .1rem;
        top: .1rem;
      }

      &--black {
        &:before {
          background: url(#{$assetsPath}img/icons/clock-black.svg) no-repeat center;
          background-size: contain;
        }
      }
    }
  }
}