@import "../../blocks/colors";
@import "../../abstracts/vars";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 5px 1rem;
  transition: .3s;
  text-decoration: none;
  text-align: center;
  line-height: initial;
  word-break: initial;

  &:focus,
  &:active {
    outline: none;
  }

  &--assist-end:hover::after {
    content: 'Отменить';
  }

  &--assist-end::after {
    content: 'Помогаю';
  }

  &--assist-begin::after {
    content: 'Готов помогать';
  }

  &--offer::after {
    content: 'Продолжить' !important;
  }

  &:hover {
    text-decoration: none;
  }

  &--transparent {
    background-color: transparent;
    border: 1px solid transparent;
    outline: none;

    &:focus{
      outline: none;
    }
  }

  &--primary {
    color: #fff;
    background-color: $primary;

    &:hover,
    &:focus {
      color: #fff;
      background-color: $primary;
    }

    &:focus:active {
      background-color: $primary;
    }

    &:disabled {
      background-color: #d6dade;
    }
  }

  &--primary-whiteBg {
    border-color: $primary;
    color: $primary;
    background-color: transparent;

    svg {
      fill: #000;
    }

    &:hover,
    &:focus {
      color: #fff;
      background-color: $primary;

      svg {
        fill: #fff;
      }
    }

    &:focus:active {
      background-color: $primary;
    }

    &:disabled, &--fakeDisabled {
      background-color: #d6dade;
      border-color: #d6dade;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    &--with-location {
      &:before {
        content: url(#{$assetsPath}img/icons/location.svg);
        display: block;
        width: 1.375rem;
        height: 1.375rem;
        margin-right: .5rem;
      }
    }
  }

  &--white {
    color: $primary;
    border-color: $primary;
    background-color: #fff;

    &.toggled {
      background-color: $primaryFade;
    }

    &:hover,
    &:focus {
      background-color: #ffffff;
    }

    &:focus:active {
      background-color: #ffffff;
    }

    &:disabled {
      background-color: #f3f5f7;
    }
  }

  &--grey {
    color: $black;
    border-color: #F3F5F7;
    background-color: #F3F5F7;

    &.toggled {
      background-color: $lightGrey;
    }

    &:hover {
      color: $black;
      background-color: $lightGrey;
    }

    &:focus:active {
      background-color: $lightGrey;
    }

    &:disabled {
      background-color: #f3f5f7;
    }

    &-bordered {
      border-color: #d6dade;
    }
  }

  &--grey-whiteBg {
    color: $black;
    border-color: $lightGrey;
    background-color: #fff;

    &.toggled {
      background-color: $lightGrey;
    }

    &:hover {
      background-color: $lightGrey;
      color: $black;
    }

    &:focus:active {
      background-color: $lightGrey;
    }

    &:disabled {
      background-color: #f3f5f7;
    }

    .spinner-border {
      color: $grey;
    }
  }

  &--transparent {
    color: $primary;
    border-color: transparent;
    background-color: transparent;

    &:hover,
    &:focus {
      color: $primary;
      background-color: transparent;
    }

    &:focus:active {
      color: $primary;
      background-color: transparent;
    }

    &:disabled {
      color: $lightGrey;
      background-color: transparent;
    }
  }

  &--white-transparentBg {
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;

    &:hover,
    &:focus {
      color: $black;
      background-color: #fff;
    }

    &:focus:active {
      color: $black;
      background-color: #fff;
    }

    &:disabled {
      background-color: #d6dade;
      border-color: #d6dade;
    }
  }

  &--small-padding {
    padding: 0 1rem;
  }

  &--height-48 {
    min-height: 3rem;
  }

  &--height-40 {
    min-height: 2.5rem;
  }

  &--delete {
    width: 34px !important;
    padding: 2px;
    height: 34px;
  }

  &--bold {
    font-weight: 600;
  }

  &--width-auto {
    width: auto;
  }

  &--arrow-prev {
    .arrow__line {
      position: absolute;
      width: .5rem;
      height: 1px;
      display: block;
      transition: background-color .3s;

      &--pink {
        background-color: $primary;
      }

      &--blue {
        background-color: $primary;
      }

      &:first-child {
        bottom: 0.7rem;
        left: 0;
        -webkit-transform: rotate(-35deg);
        transform: rotate(-35deg);
      }

      &:last-child {
        bottom: 0.4rem;
        left: 0;
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
      }
    }

    &:hover {
      .arrow__line {
        background-color: $primary;
      }
    }
  }

  &--arrow-next {
    .arrow__line {
      position: absolute;
      width: .5rem;
      height: 1px;
      display: block;
      transition: background-color .3s;

      &--pink {
        background-color: $primary;
      }

      &:first-child {
        bottom: 0.7rem;
        right: 0;
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
      }

      &:last-child {
        bottom: 0.4rem;
        right: 0;
        -webkit-transform: rotate(-35deg);
        transform: rotate(-35deg);
      }
    }

    &:hover {
      .arrow__line {
        background-color: $primary;
      }
    }
  }

  &--arrow-next-bottom {

    &--blue {
      color: $primary;
    }

    .arrow__line {
      position: absolute;
      width: .5rem;
      height: 1px;
      display: block;
      transition: background-color .3s;

      &--pink {
        background-color: $primary;
      }

      &--blue {
        background-color: $primary;
      }

      &:first-child {
        bottom: 0.6rem;
        right: -0.09rem;
        transform: rotate(45deg);
      }

      &:last-child {
        bottom: 0.6rem;
        right: -0.4rem;
        transform: rotate(-45deg);
      }
    }

    &:hover {
      .arrow__line {
        background-color: $primary;
      }
    }
  }

  &--arrow-prev-icon {
    display: flex;
    margin: 0;
    padding: 0;
    width: auto;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    font-family: "PT Sans", sans-serif;
    line-height: 1.5rem;

    &:before {
      content: "";
      display: inline-block;
      width: 1.375rem;
      height: 1.375rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &--blue {
      color: $primary;

      &:before {
        background-image: url(#{$assetsPath}img/icons/arrow-left-blue.svg);
      }
    }
  }

  &--contact {
    padding: 0 2.5rem;
    border-radius: 3px;
  }

  &--no-padding {
    padding: 0;
  }

  &--text-center {
    text-align: center;
  }

  .button__spinner {
    display: none;
  }

  &--spin {
    .button__spinner {
      display: block;
    }
  }
}

.button-create-entity-white {
  display: inline-flex;
  align-items: center;
  width: auto;
  min-height: 3.125rem;
  background: #F3F5F7;
  border-radius: 8px;
  padding: 10px 1.875rem;
  color: #0C1014;

  a {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
}

.button-create-entity-primary {
  display: flex;
  width: 100%;
  min-height: 3rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.0800371);
  border-radius: 4px;
  background-color: $primary;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
  background-color: #eee;
}

.tab-button {
  height: 48px;
  max-height: 48px;

  &.active {
    background-color: $primary;
    color: #fff;
  }
}
