@import "../../../blocks/colors";

.form__input_textarea {
  height: auto;
  padding-top: 1rem !important;

  &:focus {
    padding-top: 1.5rem;
  }

  &_error {
    border-color: $primary !important;
  }

  & ~ .form__input_label {
    left: 1px;
    top: 1px;
    background: #fff;
    padding: .3rem 1rem;
  }

  &--padding-inherit {
    padding: 1rem !important;
  }

  &-counter {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0 5px;
  }

  &-counter-with-scroll {
    right: 25px;
  }
}

.form__textarea_label {
  &--default {
    position: absolute;
    padding: 0.3rem 1rem;
    background-color: #ffffff;
    margin-top: 1px;
    margin-left: 1px;
  }
}