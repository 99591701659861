.new-channel {
    background-color: #ffffff;
    margin: 0 -1.875rem;
    height: 100%;

    &__wrapper {
        height: 100%;
        overflow: hidden;
        padding: 2rem 1.875rem 0 1.875rem;
        position: relative;
    }

    .search-form {
        position: absolute;
        padding: 2rem 1.875rem;
        top: 0;
        left: 0;
        right: 0;
    }

    .search-results-wrapper {
        height: 100%;
        box-sizing: border-box;
        padding: 72px 0 1rem;
    }

    .search-results {
        height: 100%;
    }
}