.breadcrumbs {
    &__list {
        display: flex;
        margin: 0;
        padding: 0;
        align-items: center;
        list-style: none;
        flex-wrap: wrap;
    }

    &__item {
        display: inline-flex;
        align-items: center;

        span {
            display: inline-block;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }

        a {
            display: inline-flex;
            align-items: center;
            text-decoration: none;
        }

        &:not(:last-child) {
            margin-right: 10px;
        }

        & + .breadcrumbs__item {
            &:before {
                content: '';
                display: inline-block;
                margin-right: 10px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #6A6E72;
            }
        }
    }
}